.message-list {
	/* padding: 10px; */
	/* padding-top: 20px; */
  }

.chat-container {
	/* margin: 20px; */
	/* background-color: aquamarine !important; */
	/* transition: transform 0.3s ease-in-out; */
	/* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.467); */
	/* border-radius: 15px !important; */
	font-size: 0.9rem;
  }
  
  .chat-container:hover {
	/* transform: scale(1.02); */
  }
  