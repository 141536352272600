.active-block {
  background-color: aquamarine;
}

.my-header {
  // background-color: #dbc3ff;
  position: relative;
  padding: 10px;
  text-align: center;
}

.header-nav {
  display: grid;
  grid-template-columns: auto 1fr;
  // justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}

.logo-container {
  // display: flex;
  // align-items: top;
}

// FOR THE MODERN
// .my-logo {
//   // width: 10vw; //used with the handwriten logo
//   width: 20vw;
//   margin-top: 10px;
//   margin-left: 20px;
//   padding: 20px; // comment for the handwriten logo
//   z-index: 2;
//   transition: filter 0.3s ease-in-out;
// }
.my-logo {
  width: 10vw;
  margin-top: 10px;
  margin-left: 20px;
  z-index: 2;
  transition: filter 0.3s ease-in-out;
}

.my-logo:hover {
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px #b97cff);
}

.header-ul {
  // list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 20px;
  margin-right: 20px;
  justify-content: flex-end;
}

.auth {
  border-left: 10px solid rgba(0, 0, 0, 0.7);
  display: flex;
}

.auth:hover {
}

.dropdown-div {
  align-items: center;
  margin: 10px;
}

.sign-out {
  grid-column: span 1;
  border: 1px solid rgb(35, 35, 35);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.7);
  background-color: #fa6089e1;
  // margin: 0px;
  justify-content: start;
  padding: 0px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  // font-size: 16px;
  // margin-top: 10px;
  color: rgb(35, 35, 35);
  // font-weight: 600;
  font-size: 1rem;
  transition-duration: 0.4s;
  // min-width: 99px;
  width: 80px;
  height: 43px;
  
}

.sign-out:hover {
  /* background-color: #b7183a88; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  scale: 98%;
}

.log-in {
  grid-column: span 1;
  border: 1px solid;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #22d6ffbc;
  // margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  // font-size: 16px;
  // margin-top: 10px;
  font-weight: 600;
  transition-duration: 0.4s;
}

.dropdown-item:not(:last-child) {
    border-bottom: 1px solid #000000;
}

@media (max-width: 676px) {
  .header-ul {
    margin-left: 30px;
    display: block;
    justify-self: end; 
    margin-right: 10px;
    margin-top: 10px;
    // min-width: 99px;
    max-width: 30vw;
    // align-items: flex-end;
    // margin: 10px 0;
  }
  .dropdown-div {
    align-items: center;
    margin: 0px;
  }
  .auth {
    /* margin-left: 100px; */
    border-top: 10px solid rgba(0, 0, 0, 0.7);
    display: block;
    border-left: none;
  }
  // FOR THE MODERN
  // .my-logo {
  //   // width: auto; // used with the handwriten logo
  //   // rotate logo for the modern logo
  //   // transform: rotate(90deg);
  //   width: 30vw;
  //   // height: 30vh;
  //   // width: auto;
  //   margin: 0px;

  //   // set the logo on the bottom of the nav (for the modern logo)
  //   margin-top: 200px;
  //   margin-left: 0px;

    
  //   padding: 0px;
  //   // max-width: 30vw; // for the handwriten logo

  //   // margin-top: 0px;
  //   // margin-left: 0px;
  //   // display: flex;
  //   // justify-self: center; 
  //   // justify-content: center;
  //   // // align-items: center;
  //   text-align: center;
  //   z-index: 2;
  //   transition: filter 0.3s ease-in-out;
  // }
  .my-logo {
    width: auto;
    // min-width: 10vw;
    max-width: 30vw;
    margin-top: 0px;
    margin-left: 0px;
    // display: flex;
    // justify-self: center; 
    // justify-content: center;
    // // align-items: center;
    text-align: center;
    z-index: 2;
    transition: filter 0.3s ease-in-out;
  }
  .my-header {
    background-color: #0bde8d;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
}
