.preferences {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #a2dfffbc;
  margin: 30px;
  max-width: 900px;
  justify-content: start;
  gap: 5px;
}

@media screen and (min-width: 768px) {
  .preferences {
    grid-template-columns: repeat(3, 1fr);
  }
}

.toggleLabel, .dropdownLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 15px;
  border-color: black;
  border: solid 1px;
  box-shadow: inset 2px 2px  rgba(0, 0, 0, 0.483);
}

.toggleLabel input[type="checkbox"], .dropdownLabel select {
  transform: scale(1.2);
  background-color: #fff;
  box-shadow: inset 2px 2px rgba(0, 0, 0, 0.2);
}