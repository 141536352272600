.art-of-the-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 20px; */
  /* align-items: center; */
  /* background-color: #f8f9fa; */
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* width: 400px; */
  /* margin: 20px auto; */
  /* background-color: rgb(165, 255, 127); */

  /*
  // ETSI MPAINEI SE KOUTI
  */
  /* border: 1px solid #6b6b6b76;
  border-radius: 5px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.55);   */
}
.art-of-the-day img {
  /* display: flex; */
  width: auto;
  height: auto;
  max-width: 60vw;
  max-height: 60vh;
  object-fit: contain;
  /* border: 4px solid #000000; */
  /* margin-left: 0px; */
  /* align-items: center; */
}
.art-of-the-day p {
  margin: 0;
  font-size: 16px;
}

.image-caption {
	font-style: italic;
	color: gray;
	text-align: center;
	margin-top: 5px;
	font-size: 14px;
  }

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* position: absolute; */
  top: 0;
  left: 0;
}

.spinner-container > div {
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .art-of-the-day {
    width: 90vw; /* Use 90% of the viewport width */
    /* margin: 20px auto; */
    /* background-color: aquamarine; */
    border: 0px;
    box-shadow: 5px 5px rgba(0, 0, 0, 0);  
  }
  .art-of-the-day img {
    max-width: 90vw;
    max-height: 80vh;
    /* background-color: aquamarine; */
    border: 0px;
    box-shadow: 5px 5px rgba(0, 0, 0, 0);  
  }
}

/* @import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@1,600&display=swap");
.quote {
  border-left: 3px solid #333;
  padding-left: 20px;
  font-style: italic;
  font-size: 1.5rem;
  margin: 20px 0;
  max-width: 410;
  color: #404040;
  /* font-family: 'Playfair Display', serif; */
  font-family: "Crimson Text", serif;
}

.quote::before {
  content: "“";
  font-size: 2em;
  line-height: 0.1em;
  margin-left: -10px;
  vertical-align: -0.4em;
  /* display: block;// Add this to move the opening quote to a separate line */
}

.quote::after {
  content: "”";
  font-size: 2em;
  line-height: 0.1em;
  margin-right: -10px;
  vertical-align: -0.4em;
}

.author {
  display:flex;
  font-weight: bold;
  font-size: 0.8em;
  text-align: right;
  margin-left: -30px;
  justify-content: flex-end;
  /* margin-top: 5px; */
  /* position: absolute; */
  /* right: -3px;  */
  /* bottom: -10px;  */
}



.character-counter {
  font-size: 0.8rem; /* Make the font size smaller */
  color: gray; /* Change the color of the text */
  /* position: absolute; */
  bottom: 0; /* Align the component to the bottom */
  right: 0; /* Align the component to the right */
  margin: 0.2rem; /* Add some margin to the component */
}


.submitButton {
  grid-column: span 1;
  color:rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
  background-color: #444;
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;
}

.submitButton:hover {
  /* background-color: #1d93d388; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #222;
}


.animate-wiggle {
  animation: wiggle 0.5s ease-in-out;
}
@keyframes wiggle {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
}