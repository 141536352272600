.chat-bubble-container {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
  }
  
  .chat-div-container {
	position: absolute;
	bottom: 70px;
	right: 0;
	width: 320px;
	height: 500px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	background-color: #ffffff;
	overflow: hidden;
	transform-origin: bottom right;
  }
  
  .chat-div-initial {
	display: none;
  }
  
  .chat-div-open {
	animation: slide-up 0.4s ease-out forwards;
  }
  
  .chat-div-closed {
	animation: slide-down 0.4s ease-out forwards;
  }
  
  .chat-bubble {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #4b7bec;
	color: #ffffff;
	cursor: pointer;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
	font-size: 24px;
	transition: transform 0.2s ease-in-out;
  }
  
  .chat-bubble:hover {
	transform: scale(1.1);
  }
  
  @keyframes slide-up {
	0% {
	  transform-origin: bottom right;
	  transform: scaleX(0) scaleY(0);
	  opacity: 0;
	}
	100% {
	  transform-origin: bottom right;
	  transform: scaleX(1) scaleY(1);
	  opacity: 1;
	}
  }
  
  @keyframes slide-down {
	0% {
	  transform-origin: bottom right;
	  transform: scaleX(1) scaleY(1);
	  opacity: 1;
	}
	100% {
	  transform-origin: bottom right;
	  transform: scaleX(0) scaleY(0);
	  opacity: 0;
	}
  }
  