/* global box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* small card styles */
.im_res_div {
    width: 100%;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    gap: 20px;
    transition: transform 0.4s, box-shadow 0.4s;
}

.im_res_div:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.52);
    transform: scale(1.01);
}

.recipe-thumbnail {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    margin: auto;
}

.recipe-title-small {
    font-weight: bold;
    font-size: 1.8rem;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    overflow-wrap: break-word;
}

.recipe-description-small {
    color: #666;
    margin-bottom: 10px;
    padding-left: 2px;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.recipe-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.recipe-footer {
    display: flex;
    font-size: 0.8rem;
    padding-left: 2px;
    justify-content: start;
    align-items: flex-end;
    gap: 20px;
}

/* details card styles */
.recipe {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.186);
    margin: 10px;
    padding: 15px;
    transition: transform 0.4s ease;
}

.recipe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.recipe-title {
    font-size: 2rem;
    font-weight: 900;
    color: #222;
    text-shadow: 2px 2px 1px rgb(129, 129, 129);
}

.close-button {
    cursor: pointer;
    font-size: 3rem;
    font-weight: bold;
    border: none;
    margin-top: -30px;
    background: transparent;
    color: #4f4f4f;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
}

.recipe-metadata {
    font-size: 0.7rem;
    text-overflow: clip;
    display: flex;
}

.recipe-difficulty,
.recipe-time {
    font-size: 0.9rem;
    margin-top: -1rem;
    padding: 5px 20px 5px 5px;
}

.recipe-body {
    border-top: 1px solid #eaeaea;
    padding-top: 10px;
    padding: 5px;
}

.recipe-description {
    border: 1px solid #222;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px 0;
    width: 100%;
}

.im-in-grid {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
    gap: 30px;
    padding: 0 15px;
    transition: transform 0.4s ease;
}

.im-container {
    align-self: flex-start; /* Ensures the image container stays at the top */
    display: flex;
    flex-direction: column; /* Maintain image and controls stack in column */
    justify-content: flex-start;
    align-items: center;

    /* items in the horizontal center */
    margin: auto;
    
    width: 100%;
    margin-top: 30px;
    /* max-width: 500px; */
    max-width: 450px;
    /* height: 450px; */
    border-radius: 8px;
    box-shadow: 5px 5px 5px rgba(55, 55, 55, 0.255);
    transition: transform 0.4s ease, width 0.5s ease, height 0.5s ease;
}

.image {
    width: 100%;
    /* height: auto; */
    object-fit: cover;
    transition: width 0.3s ease, height 0.3s ease;
    -webkit-touch-callout: none; /* disable callout on long press */
    user-select: none; /* prevent text selection */
    -webkit-user-select: none; /* prevent text selection on webkit browsers */
    -moz-user-select: none; /* prevent text selection on mozilla browsers */
    -ms-user-select: none; /* prevent text selection on IE browsers */
}

.recipe-ingredients,
.recipe-instructions {
    margin-bottom: 10px;
    min-width: 15rem;
    flex: 1;
}

.recipe-ingredients ul {
    padding-left: 20px;
}

.recipe-instructions ol {
    padding-left: 30px;
}

h3 {
    font-size: 1.4rem;
    color: #333;
}

/* button styles */
.buyButton,
.like-button,
.selection-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s, box-shadow 0.4s;
}

.buyButton {
    color: rgb(249, 249, 249);
    border: 1px solid rgb(35, 35, 35);
    box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
    background-color: #444;
    margin: 10px;
    padding: 10px;
    font-weight: 900;
    font-size: 3rem;
    border-radius: 4px;
}

.like-button {
    color: rgb(249, 249, 249);
    border: 1px solid rgb(35, 35, 35);
    box-shadow: 5px 5px rgba(255, 56, 56, 0.818);
    background-color: #ff9090;
    margin: 20px;
    padding: 10px;
    font-weight: 800;
    font-size: 2rem;
    border-radius: 4px;
}

.selection-button {
    color: rgb(249, 249, 249);
    border: 1px solid rgb(35, 35, 35);
    box-shadow: 4px 4px rgba(74, 74, 74, 0.818);
    background-color: #636363;
    margin: 5px;
    padding: 5px;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 4px;
}

.buyButton:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #222;
    transform: scale(0.98);
}

.like-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f72b2b;
    transform: scale(0.98);
}

.selection-button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #4f4f4f;
    transform: scale(0.98);
}

.selection-button:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #2d2d2d;
    transform: scale(0.96);
}

/* responsive images */
.image, .recipe-thumbnail {
    max-width: 100%;
    height: auto;
	max-width: 450px;
	margin: auto;
    -webkit-touch-callout: none; /* disable callout on long press */
    user-select: none; /* prevent text selection */
    -webkit-user-select: none; /* prevent text selection on webkit browsers */
    -moz-user-select: none; /* prevent text selection on mozilla browsers */
    -ms-user-select: none; /* prevent text selection on IE browsers */
}

/* additional responsive breakpoints */
@media (max-width: 768px) {
    .recipe {
        margin: 10px;
        padding: 15px;
        overflow-x: hidden;
    }

    .im_res_div {
        grid-template-columns: 1fr;
        overflow-x: hidden;
    }

    .recipe-thumbnail {
        width: 100%;
        height: auto;
    }

    .im-in-grid {
        flex-direction: column;
        gap: 15px;
        overflow-x: hidden;
    }
}

@media (max-width: 550px) {
    .recipe-title-small {
        font-size: 1.5rem;
    }

    .recipe-description-small {
        font-size: 0.8rem;
    }

    .buyButton, .like-button, .selection-button {
        font-size: 1.5rem;
        padding: 8px;
    }

    .recipe-footer {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .im-container {
        width: 100%;
        overflow-x: hidden;
    }

    .recipe {
        margin: 2px; 
    }
}

.light-text {
    font-size: 1rem;
    color: #555;
    max-width: 1000px;
    text-align: center;
}

.infoBox {
    padding: 10px;
    margin: 10px;
    border: solid 1px;
    border-radius: 2px;
    border-color: black;
    box-shadow: inset 3px 3px rgba(0, 0, 0, 0.483);

    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    min-width: 0;
}

.pre-order-card {
    border: #222;
}

.highlight {
    background-color: yellow; /* or any color you prefer */
    transition: background-color 0.5s ease; /* smooth transition */
  }

  @media (max-width: 400px) {
    .im-container,
    .image {
        max-width: 100%;
    }

    .im-in-grid {
        gap: 10px;
    }
}