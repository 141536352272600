.userInfo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ffe95dbc;
  margin: 30px;
  max-width: 900px;
  min-width: 94%;
  justify-content: start;
}

@media screen and (min-width: 768px) {
  .userInfo {
    grid-template-columns: repeat(3, 1fr);
  }
}

.boxDiv {
  padding: 10px;
  /* border-radius: 5px; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.infoBox {
  /* background-color: #00000014; */
  padding: 5px;
  margin: 5px;
  border: solid 1px;
  /* border-color: rgba(53, 53, 53, 0.272); */
  border-color: black;
  /* border-radius: 3px; */
  box-shadow: inset 2px 2px rgba(0, 0, 0, 0.483);
  /* word-wrap: break-word; */
  /* overflow-wrap: break-word; */
  word-break: break-all;
}

.editButton {
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #22ff7abc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}

.addEmojiButton {
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #6dcaf8c8;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}

.submitButton {
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #22d6ffbc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}

.submitButton:disabled {
  background-color: #cccccc;
  /* Gray out the background */
  color: #777777;
  /* Make the text lighter */
  border-color: #bbbbbb;
  /* Lighten the border color */
  box-shadow: none;
  /* Remove the shadow */
  cursor: not-allowed;
  /* Change cursor to not-allowed */
}

.cancelButton {
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #ff225dbc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}

.editButton:hover {
  /* background-color: #1bcc6270; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.addEmojiButton:hover {
  /* background-color: #1bcc6270; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancelButton:hover {
  /* background-color: #b7183a88; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submitButton:hover {
  /* background-color: #1d93d388; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.emojiInputGroup {
  display: flex;
  align-items: center;
  /* Vertically centers the input and button */
  gap: 0px;
  /* Adjust the space between input and button */
  margin-left: 10px;
}

.emojiInput {
  width: 30px;
  /* Adjust width as needed */
  /* font-size: 16px; Ensure it's large enough for emojis */
  padding: 5px;
  border: 1px solid rgb(35, 35, 35);
  /* Match border style with buttons */
  border-radius: 4px;
  /* Add some rounding to match overall style */
  box-shadow: inset 5px 5px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
  font-size: 20px;
}

.emojiInputContainer {
  display: flex;
  gap: 8px;
  /* Space between input, submit, and cancel buttons */
  align-items: center;
  /* Align items vertically */
}


.emojiLabel {
  margin-bottom: 8px;
  font-weight: bold;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}