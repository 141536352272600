.make-box {
	display: flex;
	flex-direction: column;
	align-items: stretch; /* Ensure items stretch horizontally */
	width: 100%; /* Add this to make it occupy full width */
	/* max-width: 10; Optional: set a max-width if desired */
	padding: 40px;
	box-sizing: border-box; /* Include padding in width calculations */
  }
  

.note-makes {
	font-weight: 600;
	color: #737373;
	padding: 0 10px;
}

.title-makes {
	font-weight: 800;
	font-size: 2rem;
	padding: 10px;
	padding-bottom: 0;
	text-shadow: 0 0 3px #686868;
}

.subtitle-makes {
	font-weight: 300;
	font-size: 0.7rem;
	padding: 0 21px;
	padding-bottom: 10px;
	margin-top: -4px;
	text-shadow: 0 0 3px #9e9e9e;
	color: #9f9f9f;
}

.comment_cards {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	gap: 35px;
	align-items: start;
	justify-content: center;
}

.im_res_div_make {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 15px;
	/* margin: 10px; */
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 8px;
	box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
	max-width: 1000px;
}

/* .im_res_div_make:hover {
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.52);
	transition-duration: 0.4s;
	scale: 100%;
} */

.recipe-make-thumbnail {
	width: auto;
	height: auto;
	max-height: 300px;
	max-width: 100%;
	object-fit: contain;
	border-radius: 4px;
	margin-top: 10px;
}

.recipe-make-user-small {
	font-weight: 600;
	font-size: 1.5rem;
	text-shadow: 0 0 3px #424242;
}

.recipe-make-comment-small {
	color: #666;
	font-size: 0.9rem;
	line-break: auto;
	padding: 10px;
}

.recipe-make-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.recipe-make-footer {
	display: flex;
	font-size: 0.8rem;
	justify-content: start;
	align-items: flex-end;
	gap: 20px;
}

.recipe-details {
	width: 100%; /* Ensure it fills the parent container */
	padding: 10px;
	margin-top: 50px;
	
	border: 3px solid #ccc;
	background-color: #ffffff;
	border-radius: 4px;
	box-sizing: border-box; /* Include padding in width calculations */
  }

  .recipe-details textarea,
  .recipe-details input[type="file"],
  .recipe-details .recaptcha-container {
	width: 100%; /* Full width */
	/* max-width: 100%; Prevent overflow */
	box-sizing: border-box; /* Include padding in width calculations */
  }
  
  .recipe-details textarea {
	height: 6rem;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	resize: vertical;
  }
  
  .recipe-details input[type="file"] {
	margin-bottom: 10px;
  }
  
  .recipe-details .recaptcha-container {
	margin-bottom: 10px;
  }

.commentButton {
	color: rgb(249, 249, 249);
	border: 1px solid rgb(35, 35, 35);
	box-shadow: 2px 2px rgba(92, 92, 92, 0.818);
	background-color: #444;
	margin: 10px 0;
	justify-content: start;
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition-duration: 0.4s;
	font-weight: 600;
	font-size: 1rem;
	width: 10rem;
	margin: 10px;
	border-radius: 6px;
}

.commentButton:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #222;
	scale: 98%;
}

.comment-form {
	width: 100%;
	box-sizing: border-box;
  }
  
  .comment-form textarea,
  .comment-form input[type="file"],
  .comment-form .recaptcha-container {
	width: 100%;
	/* max-width: 100%; */
	/* box-sizing: border-box; */
	margin-bottom: 10px;
  }
  
  .comment-form textarea {
	height: 6rem;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	/* resize: vertical; */
  }
  
  .comment-form input[type="file"] {
	padding: 5px;
  }
  
  .comment-form .recaptcha-container {
	margin-bottom: 10px;
  }
  
  .comment-form .p-2 {
	padding: 10px;
  }
  
  /* .comment-form .m-2 {
	margin: 10px 5px 0 0;
  } */
  
.captcha-container {
  width: 100%; /* make the container take full width of its parent */
  box-sizing: border-box; /* include padding and border in the element's total width and height */
  overflow: hidden; /* prevent overflow */
}

.recaptcha-container {
  width: 100%; /* make the CAPTCHA take full width of its container */
  max-width: 100%; /* prevent it from exceeding the container's width */
  box-sizing: border-box; /* include padding and border in the element's total width and height */
}
  

@media (max-width: 500px) {
	.make-box {
		padding: 15px;
	}
}