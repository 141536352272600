.userInfo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ffe95dbc;
  margin: 30px;
  justify-content: start;
  /* word-wrap: break-word;
  max-width: 100%; */
  
}
@media screen and (min-width: 768px) {
  .userInfo {
    grid-template-columns: repeat(3, 1fr);
  }
}

.boxDiv {
  padding: 10px;
  /* border-radius: 5px; */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.infoBox {
  /* background-color: #00000014; */
  padding: 5px;
  margin: 5px;
  border: solid 1px;
  /* border-color: rgba(53, 53, 53, 0.272); */
  border-color: black;
  /* border-radius: 3px; */
  box-shadow: inset 2px 2px  rgba(0, 0, 0, 0.483);
  
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  min-width: 0;


  max-width: 100%;
}

.editButton {
  grid-column: span 1;
  color:rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #22ff7abc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}
.submitButton {
  grid-column: span 1;
  color:rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #22d6ffbc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}
.cancelButton {
  grid-column: span 1;
  color:rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.7);
  background-color: #ff225dbc;
  margin: 20px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  transition-duration: 0.4s;
}

.editButton:hover {
  /* background-color: #1bcc6270; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cancelButton:hover {
  /* background-color: #b7183a88; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submitButton:hover {
  /* background-color: #1d93d388; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table {
  width: auto;
  border-collapse: collapse; 
  /* align table to the center */
  margin-left: auto;
  margin-right: auto;
  /* align-self: center; */
  table-layout:auto;
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(22, 22, 22, 0.7);
  background-color: #ffffff30;
  margin: 20px;
  margin-top: 0px;
  /* justify-content: start; */
  max-width: 500px;
  color: #000000;
  overflow: auto;

  word-wrap: break-word;

  
}

.table th, .table td {
  border: 3px solid #000000;
  padding: 10px;
  width: auto;
  word-wrap: break-word;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  /* make the text fit */
  /* word-wrap: break-word; */
  background-color: #8585853e;
  color: rgb(0, 0, 0);

  word-wrap: break-word;
}

.userTokensTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.userTokensTable th,
.userTokensTable td {
  border: 1px solid #1919190f;
  padding: 8px 12px;
  text-align: left;
}

.userTokensTable th {
  background-color: #0e0e0e2d;
}

.toggleButton {
  width: 50px;
  height: 30px; 
  grid-column: span 1;
  color: rgb(35, 35, 35);
  border: 1px solid rgb(35, 35, 35);
  padding: 0px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #83daffbc;
  cursor: pointer;
  text-align: center;
  /* display: inline-block; */
  /* font-size: 16px; */
  
  margin: 5px; 
  margin-right: 20px; 
  transition: transform 0.4s, box-shadow 0.4s;
  /* overflow: hidden; */

  /* Add a hover effect */
  &:hover {
    /* transform: translateY(-5px);
    box-shadow: 5px 5px rgba(0, 0, 0, 0.5); */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  }

  /* Add an active effect */
  /* POLI WRAIO STYLINKG*/
  &:active {
    transform: translateY(0);
    box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
    background-color: #1b5f7cbc;
  }
}
