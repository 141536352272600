.store {
	display: grid;
	grid-template-rows: auto auto auto auto;
	gap: 10px;
	grid-auto-flow: row;

	align-items: center;
	/* margin: 20px; */
	/* align-items: center; */
	/* background-color: #f8f9fa; */
	padding: 20px;
	max-width: 400px;
	width: 60%;
	/* margin: 20px auto; */
	/* background-color: rgb(165, 255, 127); */
	border-radius: 10%;
	transition-duration: 4s;

	/*
	// ETSI MPAINEI SE KOUTI
	*/
	/* border: 1px solid #6b6b6b76;
	border-radius: 5px;
	box-shadow: 5px 5px rgba(0, 0, 0, 0.55); */
}

.store img {
	/* display: flex; */
	width: 100%;
	height: 100%;
	/* max-width: 40vw;
	max-height: 40vh; */
	/* object-fit: contain; */
	object-fit: cover;
	/* border: 4px solid #000000; */
	/* margin-left: 0px; */
	/* align-items: center; */
}

.store p {
	margin: 0;
	font-size: 16px;
}



@media (max-width: 768px) {
	.store {
		width: 90vw;
		/* Use 90% of the viewport width */
		/* margin: 20px auto; */
		/* background-color: aquamarine; */
		border: 0px;
		box-shadow: 5px 5px rgba(0, 0, 0, 0);
	}

	.store img {
		/* max-width: 60vw;
		max-height: 40vh; */
		width: 100%;
		height: auto;
		/* background-color: aquamarine; */
		border: 0px;
		box-shadow: 5px 5px rgba(0, 0, 0, 0);
	}
}

.store-title {
	grid-row: 1;
	/* font-size: 18px; */
	font-weight: bold;
	text-align: center;
	/* text-transform: uppercase;  */
	font-size: 2.4rem;
	font-weight: 900;
	color: #222;
	text-shadow: 2px 2px 1px rgb(129, 129, 129);
	/* Add hard shadow */
}

.grid-im-creator {
	display: grid;
	grid-template-columns: 0.01fr 1fr;

	gap: 10px;
	/* Optional: Gap between the grid items */
	grid-row: 2;
}

.rotated-text {
	grid-column: 1;
	writing-mode: vertical-lr;
	text-align: center;
	transform: rotate(180deg);
	margin: -10px;
}

.im-container {
	position: relative;
	grid-column: 2;
	box-shadow: 5px 5px 0px rgb(55, 55, 55);
	/* border: 5px solid #222; */
	overflow: hidden;
	height: 100%;
	/* object-fit: cover; */
	/* width: 100%; */
}

.aspect-ratio-box {
	position: relative;
	width: 100%;
	height: 100%;
	/* Fill up the entire container height */
}

.aspect-ratio-box img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.grid-desc-pr-size {
	display: grid;
	grid-template-columns: 2fr 1fr;
	/* Adjusting column proportions */
	gap: 10px;
	grid-row: 3;
}

.store-description {
	grid-column: 1;
	/* overflow-y: auto; */

	grid-row: 1 / span 2;
	border: 1px solid #222;
	/* Adjusted the border for a 1px solid style */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	/* Adding shadow */
	padding: 10px;
	/* Padding to give some space inside the box */
	margin: 10px;
}

/* .price-size-grid {
	display: grid;
	grid-template-rows: repeat(2, auto); 
	grid-column: 2;
	grid-row: 1 / 3;
	gap: 10px; 
} */

.price {
	grid-column: 2;
	/* Positioned in the second column */
	grid-row: 1;
	/* Positioned in the first row */
	font-weight: 900;
	font-size: 4rem;
}

.size {
	grid-column: 2;
	/* Positioned in the second column */
	grid-row: 2;
	/* Positioned in the second row */
	font-weight: 600;
	font-size: 2rem;
}

.grid-buy-like {
	display: grid;
	grid-template-columns: 2fr 1fr;

	gap: 10px;
	/* Optional: Gap between the grid items */
	grid-row: 4;
}

.promo-text {
	font-size: 1.1rem;
	font-weight: 500;
	/* word-break:normal; */
	word-wrap: break-word;
	/* overflow-wrap: break-word; */
}

.light-text {
	font-size: 1rem;
	color: #555;
	max-width: 800px;
	text-align: center;
	/* margin-bottom: 2rem; */
}


.buyButton {
	grid-column: 1;
	color: rgb(249, 249, 249);
	border: 1px solid rgb(35, 35, 35);
	/* padding: 10px; */
	box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
	background-color: #444;
	margin: 10px;
	justify-content: start;
	padding: 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	/* Vertical alignment */
	justify-content: center;
	width: auto;
	transition-duration: 0.4s;
	font-weight: 900;
	font-size: 3rem;
}

.stretchedText {
	display: inline-block;
	transform: scaleX(1.5);
	/* or any other value you prefer */
}

.like-section {
	display: flex;
	align-items: left;
	flex-direction: column;
}

.store-like-button {
	grid-column: 2;
	color: rgb(249, 249, 249);
	border-radius: 0px;
	border: 1px solid rgb(35, 35, 35);
	box-shadow: 4px 4px rgba(255, 56, 56, 0.818);
	background-color: #ff9090;
	margin: 20px;
	/* margin-bottom: 5px; */
	padding: 10px;
	cursor: pointer;
	transition-duration: 0.1s;
	transition: transform 0.2s;
	font-weight: 800;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	/* position: relative; */
	transition: all 0.1s ease-in-out;
}

.store-like-button.liked {
	/* styles for the liked state *
	color: #ffececd7;
	/* background-color: #a14f4f; */
	/* box-shadow: inset 0 0 3px 3px rgba(255, 255, 255, 0.308) ; */
	/* border-radius: 10px; */
	background-color: rgb(255, 202, 202);
	box-shadow: 2px 2px rgba(255, 56, 56, 0.818);
	transform: scale(0.96); /* slightly reduce the size */
	color: #ffececd7;
	text-shadow: 0 0 5px #ff7a7a, 0 0 20px #ff7a7a, 0 0 30px #ff7a7a, 0 0 40px #ff7a7a;
	border: 1px solid #ff1010;
}


.store-like-button:active {
	transform: scale(0.9);
}

.store-like-button:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #b62424;
	scale: 98%;
	text-shadow: unset;
} 

 @media (max-width: 600px) {
	.store-like-button:hover {
		background-color: #ff9090;
		box-shadow: 4px 4px rgba(255, 56, 56, 0.818);
		scale: 100%;
	}
	.store-like-button:active {
		transform: unset;
	}

	.store-like-button.liked {
		/* styles for the liked state */
		color: #ffececd7;
		/* box-shadow: inset 0 0 3px 3px rgba(255, 255, 255, 0.308) ; */
		box-shadow: none;
		transform: scale(0.96); /* slightly reduce the size */
	}
	
  }

.like-count {
	display: flex;
	/* font-size: 1.2em; */
	/* font-weight: 900; */
	/* color: #e54343; */
	/* margin-left: 30px; */
	align-self: center;
	margin-top: -10px;
	letter-spacing: 2px;
	/* text-shadow: #ff7a7a; */

}

.like-zeros {
	color: gray;
}

.like-actual {
	color: rgb(255, 50, 50);
	font-weight: 500;
	text-shadow: 0 0 5px #ff7a7a, 0 0 20px #ff7a7a, 0 0 30px #ff7a7a, 0 0 40px #ff7a7a;
}

.selection-button {
	/* grid-column:  2; */
	color: rgb(249, 249, 249);
	border: 1px solid rgb(35, 35, 35);
	/* padding: 10px; */
	box-shadow: 4px 4px rgba(74, 74, 74, 0.818);
	background-color: #636363;
	margin: 5px;
	padding: 5px;
	transition-duration: 0.4s;
	font-weight: 600;
	font-size: 1rem;
}

.buyButton:hover {
	/* background-color: #1d93d388; */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #222;
	scale: 98%;
}



.selection-button:hover {
	/* background-color: #1d93d388; */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #4f4f4f;
	scale: 98%;
}

.selection-button:active {
	/* background-color: #1d93d388; */
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	background-color: #2d2d2d;
	scale: 96%;
}

@media only screen and (max-width: 600px) {

	.prev-button,
	.next-button {
		opacity: 1 !important;
		visibility: visible !important;
	}
}


.infoBox {
	/* background-color: #00000014; */
	padding: 10px;
	margin: 10px;
	border: solid 1px;
	border-radius: 2px;
	/* border-color: rgba(53, 53, 53, 0.272); */
	border-color: black;
	/* border-radius: 3px; */
	box-shadow: inset 3px 3px rgba(0, 0, 0, 0.483);

	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	min-width: 0;


	/* max-width: 100%; */
}

.pre-order-card {
	border: #222;
}