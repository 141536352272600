.card {
  position: relative;
  width: 2082px;
  /* Width of your PNG */
  height: 2103px;
  /* Height of your PNG */
  font-family: "Inter", sans-serif;
}

:root {
  --main-color: #edbc09;
  /* Red color */
}

.frame {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 1;
  /* opacity: 30%; */
}

.coffee-img-div {
  top: 295px;
  left: 24px;
  position: absolute;
  display: flex;
  /* background-color: #ffcc00; */
  width: 995px;
  height: 995px;
  color: #765838;
  /* opacity: 60%; */
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coffee-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  z-index: 3;
}

.text {
  position: absolute;
  color: #fff;
  /* Choose a color that stands out against your background */
  font-size: 24px;
  /* Adjust size as needed */
  padding: 10px;
  /* Padding to ensure text doesn't touch the edges */
}

.shopname {
  top: 60px;
  left: 420px;
  color: #765838;
  font-size: 120px;
  font-weight: 600;
  z-index: 10;
}

.rate-color {
  background-color: var(--main-color);
  /* Using the variable */
  color: #fff;
  position: absolute;
  width: 314px;
  height: 314px;
  top: 24px;
  left: 24px;
  padding: 10px;
  z-index: 10;
  /* Lower z-index if under the frame */
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 75px;
  color: #765838;
  font-size: 130px;
  font-weight: 900;
}

.map-img {
  position: absolute;
  /* width: 1400px;
  height: 1400px; */
  top: 500px;
  left: 900px;
  z-index: -1;
  /* Lower z-index if under the frame */
}

.map-pin {
  position: absolute;
  width: 30px;
  /* Total diameter of the dot */
  height: 30px;
  /* Total diameter of the dot */
  border-radius: 50%;
  /* Makes the shape a circle */
  background-color: #ff7b00;
  /* Background color of the dot */
  border: 10px solid #765838;
  /* Stroke thickness and color */
  top: 1130px;
  left: 1515px;
  margin-right: 10px;
  /* Space between the dot and the text */
}

.map-text {
  /* top: 1050px;
    left: 1565px; */
  align-self: last baseline;
  justify-content: left;

  color: #ff7b00;
  text-shadow: 4px 4px 2px #765838, 0px 0px 8px #ffe7d1;
  text-stroke: 30px;
  font-size: 55px;
  padding-right: 30px;
  font-weight: 800;
  z-index: 5;
}

.map-text-div {
  top: 750px;
  left: 1565px;
  position: absolute;
  display: flex;
  /* background-color: #00ff99; */
  width: 500px;
  height: 400px;
  color: #765838;
  opacity: 90%;
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coor-div {
  top: 295px;
  left: 1045px;
  position: absolute;
  /* background-color: #35ff02; */
  width: 1010px;
  height: 180px;
  /* opacity: 30%; */
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coordinates {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  /* top: 330px;
    left: 1060px; */
  color: #765838;
  font-size: 65px;
  z-index: 10;
  font-weight: 700;
}

.rate-div {
  top: 1790px;
  left: 110px;
  position: absolute;
  background-color: #ffe7d1;
  width: 1500px;
  height: 250px;
  /* opacity: 30%; */
  z-index: 1;
  /* Lower z-index if under the frame */
}

.rate-bar {
  background-color: var(--main-color);
  width: 70%;
  height: 100%;
  border-radius: 240px;
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coffee-div {
  top: 1314px;
  left: 24px;
  position: absolute;
  /* background-color: #00ff99; */
  width: 905px;
  height: 258px;
  color: #765838;
  /* opacity: 30%; */
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coffee-type {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-top: 20px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 80px;
}

.coffee-format {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  /* padding: 20px; */
  padding-left: 35px;
  font-weight: 200;
  font-size: 44px;
}

.city-div {
  top: 1484px;
  right: 24px;
  position: absolute;
  /* background-color: #00ff99; */
  width: 1005px;
  height: 258px;
  color: #765838;
  /* opacity: 30%; */
  z-index: 1;
  /* Lower z-index if under the frame */
}

.coffee-text {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  padding-top: 160px;
  padding-right: 30px;
  font-weight: 700;
  font-size: 70px;
  text-shadow: 0px 0px 8px #ffe7d1;
}

.qr-div {
  bottom: 60px;
  right: 30px;
  position: absolute;
  /* background-color: #00ff99; */
  width: 275px;
  height: 275px;
  color: #765838;
  /* opacity: 30%; */
  z-index: 13;
  /* Lower z-index if under the frame */
}

.qr-img {
  width: 100%;
  height: 100%;
}

.partial-div {
  top: 1596px;
  left: 24px;
  position: absolute;
  display: flex;
  /* background-color: #00ff99; */
  width: 994px;
  height: 148px;
  color: #765838;
  opacity: 60%;
  z-index: 1;
  /* Lower z-index if under the frame */
}

.partial-text {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  /* flex-direction: column; */
  /* padding-top: 10px; */
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  font-size: 30px;
  max-width: fit-content;

}