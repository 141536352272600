.frame {
    position: relative;
    display: inline-block;
}

.frame img {
    width: 100%;
}

.title {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
}

.enlarge{
    width: 120%;
    transition: all 0.5s ease;
}

.description {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    display: none;
}

.frame:hover .description {
    display: block;
}
