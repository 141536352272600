/* spinner.css */
.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-left-color: rgb(90, 203, 234);
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  .spinner-in-button {
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 10px;
	height: 10px;
	align-items: center;
	border-radius: 50%;
	border-left-color: rgb(90, 203, 234);
	animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  